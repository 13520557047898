import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { Instagram, Twitter } from '@images/icons'

const Card = ({ name, role, bio, instagramLink, twitterLink, source }) => {
	return (
		<div className='card'>
			<GatsbyImage image={source} alt={`${name}'s avatar`} />
			<div className='card__inner'>
				<div className='card-text'>
					<p className='card__name'>{name}</p>
					<p className='card__role'>{role}</p>
					<p className='card__description'>{bio}</p>
				</div>
				<div className='card__icons'>
					{twitterLink && (
						<a href={twitterLink}>
							<Twitter />
						</a>
					)}
					{instagramLink && (
						<a href={instagramLink}>
							<Instagram />
						</a>
					)}
				</div>
			</div>
		</div>
	)
}

export default Card
