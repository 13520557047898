import React from 'react'
import Layout from '@global/layout'
import { getImage, StaticImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Card from '@global/card'
import { GatsbyImage } from 'gatsby-plugin-image'

const AboutPage = ({ data }) => {
	const imageData = data.desktop.childImageSharp.fluid

	const links = [
		'https://www.metacartel.org/',
		'https://metagame.wtf/',
		'https://raidguild.org/',
		'https://www.metafactory.ai/',
		'https://chiev.net/',
		'https://daohaus.club/',
	]

	const teamMembers = [
		{
			name: 'penguin Heilman',
			role: 'Genesis PNG',
			imageName: 'penguin',
			bio: 'I am a small flightless bird utilizing web3s tool like DeFi, DAO and NFTs to be follow my passion as an entrepreneurial artistic programming scientist law student.',
			instagramLink: 'https://instagram.com/c0mput3rxz/',
			twitterLink: 'https://twitter.com/c0mput3rxz/',
		},
		{
			name: 'Kyle Stargarden',
			role: 'Cyborg Creative',
			imageName: 'kylestar',
			bio: 'Pushing the enhanced Renaissance to new levels.',
			twitterLink: 'https://twitter.com/KyleSt4rgarden/',
			instagramLink: 'https://instagram.com/st4rgarden/',
		},
		{
			name: 'Lahcen KH',
			role: 'Pixelator',
			imageName: 'lahcen1',
			bio: 'I am a crypto-space fan, a pixel arts slayer , DAOs lover  and a mathematics teacher..',
			instagramLink: 'https://instagram.com/lahcen_kha',
			twitterLink: 'https://twitter.com/lahcen_kha/',
		},
		{
			name: 'Lucas',
			role: 'TechnoAudioVisual Artist',
			imageName: 'lucas',
			bio: "A designer who's glad he might be able to earn a living through art",
			instagramLink: 'https://instagram.com/lucascerro/',
			twitterLink: 'https://twitter.com/lucascerro/',
		},
	]

	return (
		<Layout>
			<BackgroundImage Tag='section' fluid={imageData} className='bg-image'>
				<section className='section'>
					<div className='wrapper wrapper--sm-on-md'>
						<h1 className='text-center mb-6'>About</h1>

						<div className='flex flex--collapse-md'>
							<div className='about-left text-block'>
								<p>
									Defiant Pixel Society is a collective of artists, developers
									and creators that work together to generate NFTs for the
									benefit of collectively being a single brand, with fair
									representation based on value creation.
								</p>
								<StaticImage
									src='../images/about-page-left-image.png'
									alt='DPS Logo'
								/>
							</div>
							<div className='about-right text-block'>
								<StaticImage
									src='../images/about-page-right-image.png'
									alt='DPS Logo'
								/>
								<p>
									Members are encouraged to learn, share and participate.
									Members may submit up to ​2 pieces of art per month for a
									share for each piece that gets minted by the DAO.
									Alternatively, individuals can participate in co-creation.
									Contributing to co-creation or group themes that get minted
									earn a share but does not count as a piece submitted for the
									month.
								</p>
								<p>
									This monthly “submission” gives members access to our
									community channels. All members of the DAO receive equal
									voting rights.
								</p>
								<p>
									Members can also earn shares by leading workshops or
									introducing new tools and techniques.
								</p>
							</div>
						</div>
					</div>
				</section>
			</BackgroundImage>

			<section className='section team-members'>
				<div className='gradient' />
				<div className='wrapper'>
					<h2 className='text-center mb-6'>Team Members</h2>
					<div className='grid grid--stretch  grid--4'>
						{teamMembers.map(({ imageName, ...rest }) => {
							const image = data.avatars.edges.find(
								({ node }) => node.name === imageName
							)
							return (
								<Card
									source={getImage(image.node)}
									key={image.node.id}
									{...rest}
								/>
							)
						})}
					</div>
				</div>
			</section>

			<section className='projects'>
				<div className='wrapper'>
					<div className='projects__inner'>
						{data.projects.edges.map(({ node }, index) => {
							return (
								<a href={links[index]} target='_blank' rel='noreferrer'>
									<GatsbyImage
										key={node.id}
										image={getImage(node)}
										alt={`${node.name}'s avatar`}
									/>
								</a>
							)
						})}
					</div>
				</div>
			</section>
		</Layout>
	)
}

export default AboutPage

export const query = graphql`
	query AboutPageQuery {
		avatars: allFile(filter: { sourceInstanceName: { eq: "avatars" } }) {
			edges {
				node {
					name
					id
					childImageSharp {
						gatsbyImageData(
							layout: CONSTRAINED
							width: 360
							placeholder: BLURRED
						)
					}
				}
			}
		}
		projects: allFile(filter: { sourceInstanceName: { eq: "projects" } }) {
			edges {
				node {
					name
					id
					childImageSharp {
						gatsbyImageData(layout: FIXED, width: 50, placeholder: BLURRED)
					}
				}
			}
		}
		desktop: file(relativePath: { eq: "Idea.png" }) {
			childImageSharp {
				fluid(quality: 100, maxWidth: 2560) {
					...GatsbyImageSharpFluid_withWebp
				}
			}
		}
	}
`
